// Please put errors specific to auth in this file. If it's a general error,
// consider putting it in js/common/services/errorHelper
import { css } from '@emotion/react';
import { palette } from '@leafygreen-ui/palette';

import { showIntercom } from 'js/common/services/showHideIntercom';

const INVALID_PASSWORD_ERROR = (
  <>
    <strong>The password you entered is insecure.</strong> Your new password must contain at least 8 characters and
    cannot contain any part of your email address.
  </>
);

const PASSWORD_TOO_COMMON_ERROR = (
  <>
    <strong>The password you entered is insecure.</strong> Enter a new password that contains unique characters,
    numbers, or symbols.
  </>
);

const PASSWORD_HISTORY_ERROR = (
  <>
    <strong>The password you entered is insecure.</strong> Your new password must contain at least 8 characters, cannot
    contain any part of your email address, and is different from your previous 4 passwords.
  </>
);

const textLink = css({
  textDecoration: 'none',
  color: palette.blue.base,
  cursor: 'pointer',
});

export const getErrorMessageFromCode = function getErrorMessageFromCode(
  errorCode,
  { siteName, marketingUrl }: any = {}
) {
  const errors = {
    NEEDS_SETUP: 'Cannot create account. System is still being configured.',
    SERVER_ERROR: 'The request contained invalid data.',
    INVALID_GROUP_KEY: 'Invalid customer key.',
    NO_VALID_LDAP_GROUP: `This account is not eligible to login ${siteName ? `to ${siteName}` : ''}`,
    INVALID_USER_AUTH: 'Please provide a valid username and password.',
    MISSING_CREDENTIALS: 'Please enter your username and password.',
    USER_DELETED: 'Please provide a valid username and password.',
    INVALID_USERNAME: 'Please provide a valid username.',
    MULTI_FACTOR_AUTH_NOT_CONFIGURED: "Sorry, you can't login at this time. Please contact support",
    LOCKED_FAILED_LOGIN_ATTEMPTS:
      // eslint-disable-next-line max-len
      'We have locked your account due to too many failed login attempts. Contact your company administrator to unlock your account.',
    TEMPORARILY_LOCKED_FAILED_LOGIN_ATTEMPTS:
      "We've locked your account for 30 minutes due to multiple failed login attempts.",
    LOCKED_INACTIVE_ACCOUNT:
      'This account is inactive and has been locked. Please contact your administrator to have your account unlocked.',
    ACCOUNT_LOCKED: 'Your account is locked. Please contact your admin to unlock your account.',
    ACCOUNT_SUSPENDED: 'Your account has been suspended due to inactivity. Please reset your password to login again.',
    INVALID_PASSWORD_TOO_SHORT: INVALID_PASSWORD_ERROR,
    INVALID_PASSWORD_TOO_LONG: 'The password you entered is too long.',
    MULTI_FACTOR_AUTH_CHECK_FAILED: 'Sorry, the authentication code you provided is incorrect.',
    MULTI_FACTOR_AUTH_SMS_FAILED: 'Sorry, we were unable to send the auth code to your phone.',
    INVALID_PHONE_NUMBER:
      // eslint-disable-next-line max-len
      'The phone number entered is invalid. If this is not a US phone number try entering a + sign, followed by your country code, followed by your number. Some countries require a + sign, followed by your country code, followed by the number 1, followed by your number. For example, if your country code is 52 and your number is 9875551212, try +529875551212 or +5219875551212.',
    INVALID_MOBILE_NUMBER: 'The phone number entered is invalid.',
    NO_INTERNATIONAL_CALLS: 'Voice calls to international numbers are not supported.',
    NO_PHONE_NUMBER: 'No phone number was specified.',
    MULTI_FACTOR_AUTH_VOICE_CALL_FAILED: 'A voice call could not be sent.',
    MULTI_FACTOR_AUTH_BACKUP_EQUALS_PRIMARY:
      'The specified backup number is the same as the primary phone number. Please choose a different phone number.',
    MULTI_FACTOR_AUTH_PRIMARY_EQUALS_BACKUP:
      'The specified primary number is the same as the backup phone number. Please choose a different phone number.',
    INVALID_PASSWORD_EXPIRED: 'Your password has expired, please reset your password.',
    PASSWORD_IS_EMPTY: 'Your password is empty, please reset your password.',
    CANNOT_SEND_RESET_EMAIL: "We're sorry, an unexpected error has occurred.",
    REGISTRATION_DISABLED: 'Registration could not be processed. Please try again later.',
    CANNOT_DISABLE_MULTI_FACTOR_AUTH: 'Multi-factor authentication is required and cannot be disabled.',
    RATE_LIMITED: 'Too many attempts for this user. Please try again in a few minutes.',
    RATE_LIMITED_IP: 'Too many attempts for this IP Address. Please try again in a few minutes.',
    IDLE_SESSION_TIMEOUT:
      "For your security, you've been logged out. To start a new session, simply login when you're ready.",
    INVALID_FIRST_NAME: 'Your first name cannot contain more than 20 characters.',
    INVALID_LAST_NAME: 'Your last name cannot contain more than 20 characters.',
    INVALID_INVITATION_TOKEN:
      'The invitation token is invalid. Please contact your administrator to send you a new invite.',
    MISSING_VALID_INVITE_TOKEN_OR_ACTIVATION_CODE: (
      <>
        You do not have a valid invitation to {siteName}. To request access, contact your organization&apos;s admin. If
        you want an invitation to {siteName}, visit our{' '}
        <strong>
          <a href={marketingUrl} target="_blank" rel="noreferrer">
            website
          </a>
        </strong>
        .
      </>
    ),
    EMPLOYEE_ACCESS_DENIED: (
      <>
        You do not have permission to access {siteName}. To request access, submit a request through{' '}
        <strong>
          <a href="https://mana.corp.mongodb.com" target="_blank" rel="noreferrer">
            MANA
          </a>
        </strong>
        .
      </>
    ),
    // This could happen if the user refreshes the page with an older auth code
    INVALID_GRANT: 'It took too long to authenticate. Please refresh the page and try again',
    JIT_FAILURE: (
      <>
        We were unable to log you in with that login method. Try logging in with an email address and password.
        <br />
        If you continue to have issues logging into your account, contact our{' '}
        <a
          role="button"
          onClick={() => {
            showIntercom();
          }}
          tabIndex={0}
          css={textLink}
        >
          Support team
        </a>
        .
      </>
    ),
    ACCESS_DENIED:
      'We were unable to log you in with that login method. Ensure that you have a public verified email address set on your GitHub account.',
    CANNOT_LINK_MULTIPLE_SOCIAL_PROVIDERS:
      'We were unable to log you in with that login method. Log in with the current social provider ' +
      'linked to your account, either Google or GitHub. Otherwise, enter your email address and ' +
      'click "Forgot Password" to reset your password and unlink from the social provider.',
    DEFAULT: 'A server error occurred. Please try again in a minute.',
    SOCIAL_USERNAME_DOMAIN_REQUIRES_FEDERATION: (
      <>
        <strong>The email you tried is already associated with your company or organization. </strong>
        Please login with your username or email.
      </>
    ),
    INVALID_INVITATION_USERNAME_MISMATCH:
      'Unable to process the invitation. The username you authenticated with does not match the invited user.',
    SSO_DEBUG_PROHIBITED:
      // eslint-disable-next-line max-len
      "SSO debugging must be must be enabled for this Identity Provider and used with a username that matches against the Identity Provider's associated domains list.",
    USER_ORGS_RESTRICTED:
      "This invitation is no longer valid based on your company's organization membership restrictions.",
    SESSION_USER_MISMATCH: "We're sorry, an unexpected error has occurred.",
    UNABLE_TO_CHANGE_USERNAME: 'We were unable to change your username on login. Please contact support.',
    ACCOUNT_DELETION_REQUESTED: (
      <>
        <strong>Your MongoDB account deletion request has been submitted and is in progress.</strong>
        <br />
        You will no longer be able to log in or create a new account with this email address.
      </>
    ),
    LOGIN_WITH_RETIRED_USERNAME: (
      <>
        <strong>This email address can no longer be associated with a MongoDB Account. </strong>
        <br />
        Sign up for a new account with a different email address.
      </>
    ),
    INVALID_VERCEL_ACCESS_CODE: (
      <>The authorization code is invalid. Please close this window and start over the integration flow.</>
    ),
    USER_NEEDS_TO_VERIFY_EMAIL: (
      <>
        Your email address is not verified. Please verify your email address by logging in through{' '}
        <a href="https://account.mongodb.com/account/login?signedOut=true" target="_blank">
          MongoDB Account Login UI
        </a>{' '}
        before attempting to integrate your Vercel account with Atlas.
      </>
    ),
  };

  const message = errors[errorCode];

  return message || errors.DEFAULT;
};

export const getErrorMessageFromCodeForRegistration = function getErrorMessageFromCodeForRegistration(
  errorCode,
  { siteName }: any = {}
) {
  // TODO: @CLOUDP-79748 - Remove unneeded error codes from this object, as well as the backend.
  const errors = {
    INVALID_USERNAME: 'Please provide a valid email address.',
    INVALID_PHONE_NUMBER: 'Please provide a valid phone number.',
    DUPLICATE_USERNAME: 'An account with that email address already exists. Please login to continue.',
    DUPLICATE_EMAIL: 'An account with that email address already exists. Please use a unique email.',
    INVALID_GROUP_NAME:
      // eslint-disable-next-line max-len
      'Please provide a valid company name. The company name length must not exceed 64 characters and can only contain letters, numbers, spaces, dashes, and underscores.',
    INVALID_GROUP_NAME_10GEN: 'Company names cannot include 10gen- or -10gen.',
    GROUP_NAME_NOT_FOUND: 'No company with the specified name was found.',
    INSECURE_PASSWORD: INVALID_PASSWORD_ERROR,
    INVALID_PASSWORD_TOO_SHORT_ONPREM: (
      <>
        <strong>The password you entered is insecure.</strong> It must have at least 8 characters, one letter, one
        number, and one special character.
      </>
    ),
    INVALID_PASSWORD_TOO_COMMON: PASSWORD_TOO_COMMON_ERROR,
    MISSING_CAPTCHA_RESPONSE: 'Please wait a moment so that we can verify that you are not a robot.',
    USER_INTERNAL_FAILURE:
      // eslint-disable-next-line max-len
      "We're sorry, your registration could not be processed at this time. Please contact our support team for assistance.",
    USERNAME_CONTAINS_RESERVED_DOMAIN:
      'Sorry, the provided email address contains a reserved domain name. Please try a different email address.',
    REGISTER_WITH_RETIRED_USERNAME: 'You cannot create a new MongoDB account with this email address.',
  };

  return errors[errorCode] ? errors[errorCode] : getErrorMessageFromCode(errorCode, siteName);
};

export const getResetErrorMessageFromCode = function getResetErrorMessageFromCode(errorCode) {
  const resetErrorMessages = {
    INVALID_USERNAME: 'Please enter your username.',
    INVALID_EMAIL_ADDRESS: 'Please provide a valid email address.',
    INVALID_PASSWORD: 'Password is not valid.',
    INVALID_GROUP_KEY: 'API key is not valid.',
    PASSWORDS_DO_NOT_MATCH: 'Passwords do not match.',
    PASSWORD_IS_EMPTY: 'Your password is empty, please reset your password.',
    INVALID_PASSWORD_TOO_SHORT: PASSWORD_HISTORY_ERROR,
    INSECURE_PASSWORD: PASSWORD_HISTORY_ERROR,
    INVALID_PASSWORD_EXPIRED: 'Your password has expired, please enter a new password. ',
    INVALID_PASSWORD_TOO_COMMON: PASSWORD_TOO_COMMON_ERROR,
    PASSWORD_RESET_EXPIRED: 'Password reset expired.',
    MULTI_FACTOR_AUTH_RESET_EXPIRED: 'Two-Factor Authentication reset expired.',
    PASSWORD_RESET_USERNAME_MISMATCH: 'Username does not match reset username',
    PASSWORD_RESET_IP_ADDRESS_MISMATCH: 'Not the same IP address you submitted the reset from.',
    SERVER_ERROR: 'A server error occurred. Please try again in a minute.',
    RATE_LIMITED: 'Too many attempts. Please try again in a few minutes.',
    REPEATED_PASSWORD: 'This password was used recently, please choose a different password.',
    DATABASE_ACCOUNT_CANNOT_BE_FOUND: 'The database username and password combination is invalid.',
    MULTI_FACTOR_AUTH_USER_NOT_IN_GROUP:
      'Two-factor authentication reset is only allowed for direct project users, ' +
      'not users with project access through a Team.',
    INVALID_AGENT_API_KEY: 'The agent API key provided for resetting two-factor authentication could not be found.',
    INVALID_PASSWORD_TOO_SHORT_ONPREM: (
      <>
        <strong>The password you entered is insecure.</strong> It must have at least 8 characters, one letter, one
        number, and one special character.
      </>
    ),
  };

  const message = resetErrorMessages[errorCode];
  return message || resetErrorMessages.SERVER_ERROR;
};

const errorCodes = [
  'INVALID_PASSWORD_TOO_SHORT',
  'INVALID_PASSWORD_TOO_LONG',
  'INVALID_PASSWORD_EXPIRED',
  'PASSWORD_IS_EMPTY',
  'INSECURE_PASSWORD',
];

export function hasMatchingErrorCode(code) {
  return errorCodes.includes(code);
}
