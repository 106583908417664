import {
  AgentConfigOverridesView,
  AgentLogConfigView,
  AgentsView,
  AgentView,
  ApiAgentApiKeyView,
} from '@packages/types/deployment/agents';

import fetchWrapper from '../fetchWrapper';

export function getAutomationAgents(groupId: string): Promise<AgentsView> {
  return fetchWrapper(`/automation/agent/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getBackupAgents(groupId: string): Promise<AgentsView> {
  return fetchWrapper(`/backup/web/agents/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getMonitoringAgents(groupId: string): Promise<AgentsView> {
  return fetchWrapper(`/host/list/currentAgents/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function addMonitoringAgent(groupId: string, hostname: string): Promise<AgentView> {
  return fetchWrapper(`/automation/agents/monitoring/${groupId}`, {
    method: 'POST',
    body: JSON.stringify({ hostname }),
  }).then((resp) => resp.json());
}

export function removeMonitoringAgent(groupId: string, hostname: string): Promise<Response> {
  return fetchWrapper(`/automation/agents/monitoring/${groupId}/${hostname}`, {
    method: 'DELETE',
  });
}

export function addBackupAgent(groupId: string, hostname: string): Promise<AgentView> {
  return fetchWrapper(`/automation/agents/backup/${groupId}`, {
    method: 'POST',
    body: JSON.stringify({ hostname }),
  }).then((resp) => resp.json());
}

export function removeBackupAgent(groupId: string, hostname: string): Promise<Response> {
  return fetchWrapper(`/automation/agents/backup/${groupId}/${hostname}`, {
    method: 'DELETE',
  });
}

export function updateAgents(groupId: string): Promise<{}> {
  return fetchWrapper(`/automation/agents/agentUpdate/${groupId}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function cleanStaleAgents(groupId: string): Promise<{ cleanupOccurred: boolean }> {
  return fetchWrapper(`/automation/agents/cleanStaleAgents/${groupId}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function manageUnmanaged(groupId: string): Promise<{ updated: boolean }> {
  return fetchWrapper(`/automation/agents/manageUnmanaged/${groupId}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

// Add monitoring and backup agents to the specified hostname and publish
export function enableMonitoringAndBackup(groupId: string, hostname: string): Promise<{}> {
  return fetchWrapper(`/automation/agents/enableMonitoringAndBackup/${groupId}`, {
    method: 'POST',
    body: JSON.stringify({ hostname }),
  }).then((resp) => resp.json());
}

// Add monitoring agents to the specified hostname and publish
export function enableMonitoring(groupId: string, hostname: string): Promise<{}> {
  return fetchWrapper(`/automation/agents/enableMonitoring/${groupId}`, {
    method: 'POST',
    body: JSON.stringify({ hostname }),
  }).then((resp) => resp.json());
}

export function getAgentAPIKeys(groupId: string): Promise<Array<ApiAgentApiKeyView>> {
  return fetchWrapper(`/settings/${groupId}/agentApiKeys`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getMonitoringLogConfig(groupId: string): Promise<AgentLogConfigView> {
  return fetchWrapper(`/automation/agents/monitoring/logConfig/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateMonitoringLogConfig(groupId: string, logConfig: AgentLogConfigView): Promise<Response> {
  return fetchWrapper(`/automation/agents/monitoring/logConfig/${groupId}`, {
    method: 'PUT',
    body: JSON.stringify(logConfig),
  });
}

export function getBackupLogConfig(groupId: string): Promise<AgentLogConfigView> {
  return fetchWrapper(`/automation/agents/backup/logConfig/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateBackupLogConfig(groupId: string, logConfig: AgentLogConfigView): Promise<Response> {
  return fetchWrapper(`/automation/agents/backup/logConfig/${groupId}`, {
    method: 'PUT',
    body: JSON.stringify(logConfig),
  });
}

export function getConfigOverrides(groupId: string): Promise<AgentConfigOverridesView> {
  return fetchWrapper(`/automation/agents/configOverrides/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateConfigOverrides(groupId: string, configOverrides: AgentConfigOverridesView): Promise<Response> {
  return fetchWrapper(`/automation/agents/configOverrides/${groupId}`, {
    method: 'PUT',
    body: JSON.stringify(configOverrides),
  });
}
