import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import Banner from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import Toast from '@leafygreen-ui/toast';
import { H2 } from '@leafygreen-ui/typography';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Search } from '@packages/types/auth/search';
import { WindowLocation } from '@packages/types/browser';

import * as api from 'js/common/services/api';
import * as errorHelper from 'js/common/services/authErrorHelper';
import { useRequestParams } from 'js/common/context/RequestParamsContext';
import { supportedPasswordCharacters } from 'js/common/services/validationService';
// analytics
import { CloudTeams, sendError } from 'js/common/utils/bugsnag';
import { mq } from 'js/common/utils/mediaQueries';
import analytics, { SEGMENT_EVENTS } from 'js/common/utils/segmentAnalytics';

import { ResetFormValues, TParams } from 'js/auth/types/reset';

import FormInput from './FormInput';
import HeaderLogo from './HeaderLogo';
import Layout from './Layout';
import PasswordInputWithValidationCriteria from './PasswordInputWithValidationCriteria';
import { bannerStyle } from './styles/banner';
import { Container, header } from './styles/form';
import { HeaderText, resetButton, resetSuccessLoginLink } from './styles/reset';

const headerContainer = css(
  mq({
    width: ['270px', 'calc(100vw - 64px)', '270px', '270px'],
  })
);

interface ResetPasswordPageProps {
  match?: RouteComponentProps<TParams>;
  location?: Pick<Location, 'search'>;
  windowLocation?: WindowLocation;
}

export default function ResetPasswordPage({
  match: { params } = { params: '' },
  location: { search } = { search: '' },
  windowLocation = window.location,
}: ResetPasswordPageProps) {
  const { tempId } = params;
  const { onPrem } = useRequestParams();

  const { reason, email, isVercelIntegration } = queryString.parse(search) as Search;
  const [username, setUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [resetErrorCode, setResetErrorCode] = useState('');
  const [showResetSuccess, setResetSuccess] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [showToastComponent, setShowToastComponent] = useState(false);

  useEffect(() => {
    if (email) {
      setUsername(email);
    }
  }, [email]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const resetType = 'PASSWORD';
    setDisableSubmit(true);

    if (!tempId) {
      // don't proceed further if tempId is not present and username is not given
      if (!username) {
        return;
      }

      try {
        if (onPrem) {
          await api.user.resetRequest({ username, resetType });
        } else {
          await api.auth.resetPassword({ username });
        }
        setResetSuccess(true);
      } catch (error) {
        sendError({
          error,
          team: CloudTeams.CoreIam,
        });
        setResetErrorCode(error.errorCode);
        setResetSuccess(false);
        setDisableSubmit(false);
      }
    } else {
      const formVals: ResetFormValues = {
        username,
        tempId,
        password: newPassword,
        passwordConfirm: newPasswordConfirm,
        resetType,
      };

      // The current okta reset password implementation only handles passwords, not 2FA
      try {
        if (onPrem) {
          await api.user.resetComplete(formVals);
          setDisableSubmit(false);
        } else {
          const { loginRedirect } = await api.auth.resetComplete(formVals);
          setShowToastComponent(true);
          setResetErrorCode('');
          setTimeout(() => {
            setShowToastComponent(false);
            windowLocation.assign(loginRedirect);
          }, 5000);
        }
        setResetSuccess(true);
      } catch ({ errorCode }) {
        setResetErrorCode(errorCode);
        setResetSuccess(false);
        setDisableSubmit(false);
      }
    }
  };

  const sendResetPasswordAnalytics = () => {
    analytics.track(SEGMENT_EVENTS.UX_ACTION_PERFORMED, {
      action: 'Reset Password',
      context: 'Reset your password',
      username,
    });
  };

  const sendSavePasswordAnalytic = () => {
    analytics.track(SEGMENT_EVENTS.UX_ACTION_PERFORMED, {
      action: 'Save Password',
      context: 'Create a new password',
      username,
    });
  };

  const getResetErrorMsgFromReason = () => {
    let resetErrorMessage;

    if (onPrem) {
      resetErrorMessage = `Your password must be between 8 and 256 characters long and contain at least one letter, one digit and one of the following special characters:
      ${supportedPasswordCharacters}. Enter your username and choose a new password below.`;
    } else if (reason && errorHelper.hasMatchingErrorCode(reason)) {
      resetErrorMessage = errorHelper.getResetErrorMessageFromCode(reason);
    }

    return resetErrorMessage;
  };

  const usernameOrEmailTooltip = onPrem ? 'This is your username or email address you used when you registered.' : '';
  const usernameOrEmail = onPrem ? 'Username' : 'Email Address';

  return (
    <>
      <Helmet title={tempId ? 'Create New Password' : 'Reset Password'} />
      <Layout contentPlacement="left">
        <Container onSubmit={onFormSubmit} noValidate method="post">
          <HeaderLogo />
          <Toast
            data-testid="resetPasswordSuccess"
            variant="success"
            body="Your password has been successfully updated!"
            open={showToastComponent}
          />
          {tempId ? (
            <>
              <H2 css={[header, headerContainer]}>Create a new password</H2>
              {
                /* Only show success for onPrem. For Atlas and Cloud Manager we automatically log the
                  user in and show them a Toast Component */
                showResetSuccess && onPrem ? (
                  <Banner variant="success" data-testid="successBanner" css={bannerStyle}>
                    &nbsp;
                    <span>
                      <strong>Success!</strong> Your MongoDB Account password has been updated. Click{' '}
                      <Link to="/login" css={resetSuccessLoginLink}>
                        Log in
                      </Link>{' '}
                      to continue.
                    </span>
                  </Banner>
                ) : (
                  <>
                    {reason && (
                      <Banner variant="danger" css={bannerStyle} data-testid="dangerBanner">
                        <span>{getResetErrorMsgFromReason()}</span>
                      </Banner>
                    )}
                    {resetErrorCode && (
                      <Banner variant="danger" css={bannerStyle} data-testid="dangerBanner">
                        <span>{errorHelper.getResetErrorMessageFromCode(resetErrorCode)}</span>
                      </Banner>
                    )}
                  </>
                )
              }
              <FormInput
                fieldName="username"
                labelName={usernameOrEmail}
                autoComplete="username"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                tooltipText={usernameOrEmailTooltip}
              />
              {showResetSuccess ? (
                <FormInput
                  fieldName="newPassword"
                  labelName="New Password"
                  autoComplete="new-password"
                  type="password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                />
              ) : (
                <PasswordInputWithValidationCriteria
                  fieldName="newPassword"
                  labelName="New Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                />
              )}
              <FormInput
                fieldName="newPasswordConfirm"
                labelName="Confirm New Password"
                autoComplete="new-password"
                type="password"
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
                value={newPasswordConfirm}
                showCheckmark={newPasswordConfirm !== '' && newPassword === newPasswordConfirm}
              />
              <footer>
                <Button
                  variant="primary"
                  size="default"
                  type="submit"
                  disabled={username === '' || newPassword === '' || newPasswordConfirm === '' || disableSubmit}
                  css={resetButton}
                  onClick={sendSavePasswordAnalytic}
                >
                  Save Password
                </Button>
              </footer>
            </>
          ) : (
            <>
              <H2 css={header}>Reset your password</H2>
              <HeaderText>
                To reset your password, enter your email below and submit. An email will be sent to you with
                instructions about how to complete the process.
              </HeaderText>
              {showResetSuccess ? (
                <>
                  {isVercelIntegration ? (
                    <Banner variant="success" css={bannerStyle} data-testid="successBanner">
                      <span>
                        Please check your email inbox for a link to complete the reset and start over the integration
                        flow after resetting your password.
                      </span>
                    </Banner>
                  ) : (
                    <Banner variant="success" css={bannerStyle} data-testid="successBanner">
                      <span>Please check your email inbox for a link to complete the reset.</span>
                    </Banner>
                  )}
                </>
              ) : (
                <>
                  {resetErrorCode && (
                    <Banner variant="danger" css={bannerStyle} data-testid="dangerBanner">
                      <span>{errorHelper.getResetErrorMessageFromCode(resetErrorCode)}</span>
                    </Banner>
                  )}
                  <FormInput
                    fieldName="username"
                    labelName={usernameOrEmail}
                    autoComplete="email"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    tooltipText={usernameOrEmailTooltip}
                    hasError={resetErrorCode.length > 0}
                  />
                  <footer>
                    <Button
                      variant="primary"
                      size="default"
                      type="submit"
                      disabled={username === '' || disableSubmit}
                      css={resetButton}
                      onClick={sendResetPasswordAnalytics}
                    >
                      Reset Password
                    </Button>
                  </footer>
                </>
              )}
            </>
          )}
        </Container>
      </Layout>
    </>
  );
}
