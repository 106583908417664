import { createSelector } from 'reselect';
import { get, getOr, set } from 'unchanged';
import _ from 'underscore';

import * as app from '@packages/redux/modules/app';
import * as settings from '@packages/redux/modules/settings';
import * as uiPreferences from '@packages/redux/modules/uiPreferences';
import { isGroupOwner, isMonitoringAdmin } from '@packages/redux/modules/viewer';

import * as api from 'js/common/services/api';
import alphanumericCollator from 'js/common/utils/alphanumericCollator';
import fetchIfNeeded from 'js/common/utils/fetchIfNeeded';

// ActionTypes
const SET_CARDS = 'deployment/SET_CARDS';
const SET_LIST_VIEW_ITEMS = 'deployment/SET_LIST_VIEW_ITEMS';
const SET_ALL_AGENTS = 'deployment/SET_ALL_AGENTS';
const SET_AUTOMATION_AGENTS = 'deployment/SET_AUTOMATION_AGENTS';
const SET_MONITORING_AGENTS = 'deployment/SET_MONITORING_AGENTS';
const SET_AGENTS_LOADING = 'deployment/SET_AGENTS_LOADING';
const SET_DEPLOYMENT_STATUS = 'deployment/SET_DEPLOYMENT_STATUS';
const SET_DEPLOYMENT_DIFF = 'deployment/SET_DEPLOYMENT_DIFF';
const DISCARD_CHANGES = 'deployment/DISCARD_CHANGES';
const SET_MONGODB_VERSIONS = 'deployment/SET_MONGODB_VERSIONS';
const SET_AGENT_API_KEYS = 'deployment/SET_AGENT_API_KEYS';
const ALLOW_AUTOMATION_EDITS_WHILE_PUBLISHING = 'deployment/ALLOW_AUTOMATION_EDITS_WHILE_PUBLISHING';
const SHOW_CONFIRM_DEPLOYMENT_CHANGES_MODAL = 'deployment/SHOW_CONFIRM_DEPLOYMENT_CHANGES_MODAL';
const SET_LOG_COLLECTION_JOBS = 'deployment/SET_LOG_COLLECTION_JOBS';
const SET_LOG_ESTIMATES = 'deployment/SET_LOG_ESTIMATES';
const SET_CONNECTION_INFO = 'deployment/SET_CONNECTION_INFO';
const SET_PINNED_CARDS = 'deployment/SET_PINNED_CARDS';

// Agent features
const AGENT_FEATURE_MONGOMIRROR = 'mongomirror';

const initialState = {};

const addAdditionalAgentProps =
  ({ type, computeState }) =>
  (item) => ({
    ...item,
    type,
    state: computeState(item),
  });

// Reducer

function deploymentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CARDS: {
      const { groupId } = action.meta;

      return {
        ...state,
        [groupId]: {
          ...state[groupId],
          cards: action.payload,
        },
      };
    }
    case SET_LIST_VIEW_ITEMS: {
      const { groupId } = action.meta;

      return set([groupId, 'listViewItems'], action.payload, state);
    }

    case SET_AGENTS_LOADING: {
      const { groupId, agentType } = action.meta;

      const prevAgents = state?.[groupId]?.agents;

      const agents = { ...prevAgents };

      if (agentType === 'all') {
        ['automation', 'monitoring', 'backup'].forEach((t) => {
          agents[t] = { ...agents[t], fetchState: 'loading' };
        });
      } else {
        agents[agentType] = { ...agents[agentType], fetchState: 'loading' };
      }

      return {
        ...state,
        [groupId]: {
          ...state[groupId],
          agents,
        },
      };
    }

    case SET_ALL_AGENTS: {
      const { groupId } = action.meta;
      const { automation, backup, monitoring } = action.payload;

      return {
        ...state,
        [groupId]: {
          ...state[groupId],
          agents: {
            automation: {
              ...automation,
              fetchState: 'success',
              entries: automation.entries.map(
                addAdditionalAgentProps({
                  type: 'automation',
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; latestVersion: a... Remove this comment to see the full error message
                  latestVersion: automation.latestVersion,
                  computeState: (item) => (item.numProcess > 0 ? 'active' : 'no processes'),
                })
              ),
            },
            backup: {
              ...backup,
              fetchState: 'success',
              entries: backup.entries.map(
                addAdditionalAgentProps({
                  type: 'backup',
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; latestVersion: a... Remove this comment to see the full error message
                  latestVersion: backup.latestVersion,
                  computeState: (item) => (item.isPrimary ? 'active' : 'standby'),
                })
              ),
            },
            monitoring: {
              ...monitoring,
              fetchState: 'success',
              entries: monitoring.entries.map(
                addAdditionalAgentProps({
                  type: 'monitoring',
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; latestVersion: a... Remove this comment to see the full error message
                  latestVersion: monitoring.latestVersion,
                  computeState: (item) => (item.isPrimary ? 'active' : 'standby'),
                })
              ),
            },
          },
        },
      };
    }

    case SET_AUTOMATION_AGENTS: {
      const { groupId } = action.meta;
      const { automation } = action.payload;

      const newState = {
        ...state,
        [groupId]: {
          ...state[groupId],
          agents: {
            ...(state[groupId] ? state[groupId].agents : {}),
            automation: {
              ...automation,
              fetchState: 'success',
              entries: automation.entries.map(
                addAdditionalAgentProps({
                  type: 'automation',
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; latestVersion: a... Remove this comment to see the full error message
                  latestVersion: automation.latestVersion,
                  computeState: (item) => (item.numProcess > 0 ? 'active' : 'no processes'),
                })
              ),
            },
          },
        },
      };
      return newState;
    }

    case SET_MONITORING_AGENTS: {
      const { groupId } = action.meta;
      const { monitoring } = action.payload;

      const newState = {
        ...state,
        [groupId]: {
          ...state[groupId],
          agents: {
            ...(state[groupId] ? state[groupId].agents : {}),
            monitoring: {
              ...monitoring,
              fetchState: 'success',
              entries: monitoring.entries.map(
                addAdditionalAgentProps({
                  type: 'monitoring',
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; latestVersion: a... Remove this comment to see the full error message
                  latestVersion: monitoring.latestVersion,
                  computeState: (item) => (item.isPrimary ? 'active' : 'standby'),
                })
              ),
            },
          },
        },
      };
      return newState;
    }

    case SET_DEPLOYMENT_STATUS: {
      const { groupId } = action.meta;

      return {
        ...state,
        [groupId]: {
          ...state[groupId],
          deploymentStatus: action.payload,
        },
      };
    }
    case SET_DEPLOYMENT_DIFF: {
      const { groupId } = action.meta;
      return {
        ...state,
        [groupId]: {
          ...state[groupId],
          deploymentDiff: action.payload,
        },
      };
    }
    case SET_MONGODB_VERSIONS: {
      const { groupId } = action.meta;

      return set([groupId, 'versions'], action.payload, state);
    }
    case SET_AGENT_API_KEYS: {
      const { groupId } = action.meta;

      return set([groupId, 'keys'], action.payload, state);
    }
    case ALLOW_AUTOMATION_EDITS_WHILE_PUBLISHING: {
      const { groupId } = action.meta;
      return set([groupId, 'allowAutomationEditsWhilePublishing'], action.payload, state);
    }
    case SHOW_CONFIRM_DEPLOYMENT_CHANGES_MODAL: {
      const { groupId } = action.meta;
      return set([groupId, 'showConfirmDeploymentChanges'], action.payload, state);
    }
    case SET_LOG_COLLECTION_JOBS: {
      const { groupId } = action.meta;
      return set([groupId, 'logCollection', 'jobs'], action.payload, state);
    }
    case SET_LOG_ESTIMATES: {
      const { groupId, resourceName, resourceType } = action.meta;
      return set([groupId, 'logCollection', 'logEstimates', resourceType, resourceName], action.payload, state);
    }
    case SET_CONNECTION_INFO: {
      const { automationId, groupId, type } = action.meta;
      return set([groupId, 'connectionInfo', type, automationId], action.payload, state);
    }
    case SET_PINNED_CARDS: {
      const { groupId } = action.meta;
      return set([groupId, 'pinnedCards'], action.payload, state);
    }
    default:
      return state;
  }
}

export default deploymentReducer;

// Selectors
const getActiveDeployment = (state) => state.deployment[app.getActiveGroupId(state)] || {};
const getAllAgents = (state) => getActiveDeployment(state).agents || {};
export const getAutomationAgentsIfLoaded = (state) => getAllAgents(state).automation || null;

const safeGetAgents = (state, key) => {
  const agents = getAllAgents(state)[key];
  if (agents == null || agents.entries == null) {
    return { ...agents, entries: [] };
  }
  return agents;
};

export const getAutomationAgents = (state) => safeGetAgents(state, 'automation');
export const getBackupAgents = (state) => safeGetAgents(state, 'backup');
export const getMonitoringAgents = (state) => safeGetAgents(state, 'monitoring');

const getAutomationAgentFetchState = createSelector([getAllAgents], (agents) => agents.automation?.fetchState);
export const wasAutomationAgentFetchInitiated = createSelector(
  [getAutomationAgentFetchState],
  (fetchState) => fetchState === 'loading' || fetchState === 'success'
);
const getMonitoringAgentFetchState = createSelector([getAllAgents], (agents) => agents.monitoring?.fetchState);
export const wasMonitoringAgentFetchInitiated = createSelector(
  [getMonitoringAgentFetchState],
  (fetchState) => fetchState === 'loading' || fetchState === 'success'
);
const getBackupAgentFetchState = createSelector([getAllAgents], (agents) => agents.backup?.fetchState);
export const wasBackupAgentFetchInitiated = createSelector(
  [getBackupAgentFetchState],
  (fetchState) => fetchState === 'loading' || fetchState === 'success'
);
export const wereAllAgentFetchesInitiated = createSelector(
  [wasAutomationAgentFetchInitiated, wasBackupAgentFetchInitiated, wasMonitoringAgentFetchInitiated],
  (...initiatedArr) => initiatedArr.every((i) => i)
);

const getUnsortedCardsIfLoaded = (state) => getActiveDeployment(state).cards || null;
export const getMonitoredProcesses = (state) => getActiveDeployment(state).monitoredProcesses;
export const getDeploymentStatus = (state) => getActiveDeployment(state).deploymentStatus || {};
export const hasDeploymentStatus = (state) => !!getActiveDeployment(state).deploymentStatus;
export const getListViewItems = (state) => getActiveDeployment(state).listViewItems;
export const getPins = (state) => getActiveDeployment(state).pinnedCards || [];
export const getPinsSet = createSelector(
  [getPins],
  (pinned) => new Set(pinned.map((pin) => `${pin.type}-${pin.automationId}`)) as Set<string>
);
export const getIsCardPinnedFn = createSelector(
  [getPinsSet],
  (pinnedCardsSet) =>
    ({ type, automationId }): boolean =>
      pinnedCardsSet.has(`${type}-${automationId}`)
);
export const isCardPinned = (state, { type, automationId }) =>
  getIsCardPinnedFn(state)({
    type,
    automationId,
  });
export const arePinsLoaded = (state) => getActiveDeployment(state).pinnedCards != null;
export const getCardsIfLoaded = createSelector(
  [getUnsortedCardsIfLoaded, getIsCardPinnedFn, getPins],
  (cards, isPinned, pinOrder) => {
    if (!cards) {
      return cards;
    }

    const keyToIndex = pinOrder.reduce((obj, pin, idx) => {
      obj[`${pin.type}-${pin.automationId}`] = idx;
      return obj;
    }, {});

    const [pinned, unpinned] = _.partition(cards, isPinned);
    const sortedPinned = _.sortBy(pinned, (card) => keyToIndex[`${card.type}-${card.automationId}`]);
    const sortedUnpinned = unpinned.sort((a, b) => alphanumericCollator.compare(a.name, b.name));
    return [...sortedPinned, ...sortedUnpinned];
  }
);
export const getCards = createSelector([getCardsIfLoaded], (cards) => cards || []);
export const getPinnedCardsCount = createSelector([getCards, getIsCardPinnedFn], (cards, isPinned) => {
  // NOTE(JeT):
  // I'm getting the count this way instead of just using the length of getPins
  // because there could've been a pinned card that was later removed, and the server only cleans up on save,
  // so we're not guaranteed that all of the pins have a corresponding card.
  // Also this only works because I'm using the sorted getCards selector.
  const firstNonPinnedIdx = cards.findIndex((c) => !isPinned(c));
  return firstNonPinnedIdx === -1 ? 0 : firstNonPinnedIdx;
});

export const hasLegacyAgents = createSelector(
  getAutomationAgents,
  getBackupAgents,
  getMonitoringAgents,
  (automationAgents, backupAgents, monitoringAgents) => {
    const isLegacyAgent = (agent) => !agent.isModule;
    return (
      automationAgents.entries.some(isLegacyAgent) ||
      backupAgents.entries.some(isLegacyAgent) ||
      monitoringAgents.entries.some(isLegacyAgent)
    );
  }
);

// For selectors on the deploymentStatus object.
export const deploymentStatus = {
  isDraft: createSelector(getDeploymentStatus, (deploymentStatus) => deploymentStatus.isDraft),
  isPublishing: createSelector(
    getDeploymentStatus,
    (deploymentStatus) => !deploymentStatus.isDraft && !deploymentStatus.isInGoalState
  ),
  getPublishTimestamp: createSelector(getDeploymentStatus, (deploymentStatus) => deploymentStatus.publishTimestamp),
  getConfigVersion: createSelector(getDeploymentStatus, (deploymentStatus) => deploymentStatus.configVersion),
  getDeploymentState: createSelector(getDeploymentStatus, (deploymentStatus) => deploymentStatus.deploymentState || {}),
  isInGoalState: createSelector(getDeploymentStatus, (deploymentStatus) => deploymentStatus.isInGoalState),
  getStaleAgentHostnames: createSelector(
    getDeploymentStatus,
    (deploymentStatus) => (deploymentStatus.runDiagnostics && deploymentStatus.runDiagnostics.staleAgentsList) || []
  ),
  isUpgradeMode: createSelector(getDeploymentStatus, (deploymentStatus) => deploymentStatus.upgradeModeEnabled),
  securityStatusRollup: createSelector(
    getDeploymentStatus,
    (deploymentStatus) => deploymentStatus.securityStatusRollup ?? null
  ),
};

const hasFourZeroClusterBackedUp = createSelector(
  deploymentStatus.getDeploymentState,
  (deploymentState): boolean => deploymentState.hasFourZeroClusterBackedUp
);

const hasPrePITRestoreAgent = createSelector([getAutomationAgents], (agents) => {
  const minVersion = agents.minimumAgentVersionDetected;
  if (minVersion == null) {
    return false;
  }
  const [major, minor] = minVersion.split('.').map((num) => parseInt(num, 10));
  return major < 10 || (major === 10 && minor < 9);
});

export const showPrepForFourTwoBackupBanner = (state) =>
  !app.isActiveOrgAtlas(state) &&
  !uiPreferences.suppressFourTwoBackupBanner(state) &&
  !hasLegacyAgents(state) &&
  hasFourZeroClusterBackedUp(state);

export const showFourTwoBackupAgentUpgradeBanner = createSelector(
  [app.isActiveOrgAtlas, hasLegacyAgents, hasPrePITRestoreAgent, deploymentStatus.getDeploymentState],
  (isNds, hasLegacy, hasPrePIT, { isAtAutomationAgentVersion, hasFourTwoClusterBackedUp }): boolean =>
    !isNds && !hasLegacy && hasPrePIT && hasFourTwoClusterBackedUp && !isAtAutomationAgentVersion
);

export const getMongoDBVersions = (state) => getActiveDeployment(state).versions;

export const getDeploymentDiff = (state) => getActiveDeployment(state).deploymentDiff;

export const allowAutomationEditsWhilePublishing = (state) =>
  getActiveDeployment(state).allowAutomationEditsWhilePublishing || false;

export const showConfirmDeploymentChanges = (state) => getActiveDeployment(state).showConfirmDeploymentChanges || false;

export const getAutomationAgentsHostnames = createSelector(
  getAutomationAgentsIfLoaded,
  settings.isPushLiveMigrationEnabled,
  settings.isNDSPlan,
  (automationAgents, isPushLiveMigrationEnabled, isNds) => {
    if (automationAgents && automationAgents.entries) {
      // Filter the list to remove any migration hosts present, if the feature is enabled.
      // Push live migrations details here: https://wiki.corp.mongodb.com/x/0JRLBw
      const entries =
        isPushLiveMigrationEnabled && !isNds
          ? automationAgents.entries.filter((entry) => !entry?.features?.includes(AGENT_FEATURE_MONGOMIRROR))
          : automationAgents.entries;
      return entries.map((entry) => entry.hostname);
    }
    // If it's still loading automationAgents, return null as we are not certain if automationAgents are there or not
    return null;
  }
);

export const getMigrationAutomationAgentsCount = createSelector(
  getAutomationAgentsIfLoaded,
  settings.isPushLiveMigrationEnabled,
  settings.isNDSPlan,
  (automationAgents, isPushLiveMigrationEnabled, isNds) => {
    if (automationAgents && automationAgents.entries && isPushLiveMigrationEnabled && !isNds) {
      const entries = automationAgents.entries.filter((entry) => {
        return entry?.features?.includes(AGENT_FEATURE_MONGOMIRROR);
      });
      return entries.length;
    }
    return 0;
  }
);

export const getAutomationAgentsCount = createSelector(getAutomationAgents, (automationAgents) => {
  return (automationAgents && automationAgents.count) || 0;
});

export const getAgentAPIKeys = (state) => getActiveDeployment(state).keys || [];

export const isOneAgent = createSelector(
  deploymentStatus.getDeploymentState,
  getAutomationAgents,
  (deploymentState, automationAgents) =>
    deploymentState.agentType === 'ONE_AGENT' ||
    (deploymentState.agentType === 'UNKNOWN' &&
      (automationAgents.entries.length === 0 || automationAgents.entries.some((entry) => entry.isModule)))
);

export const isAutomationEditable = createSelector(
  settings.isAutomationDisabled,
  getAutomationAgents,
  deploymentStatus.getDeploymentState,
  deploymentStatus.isPublishing,
  allowAutomationEditsWhilePublishing,
  deploymentStatus.isUpgradeMode,
  (disabled, automationAgents, deploymentState, publishing, allowEditsWhilePublishing, upgradeMode): boolean =>
    !disabled &&
    !upgradeMode &&
    !automationAgents.isAnyAgentVersionDeprecated &&
    deploymentState.areAnyVersionsAvailable &&
    (!publishing || allowEditsWhilePublishing)
);

export const isManageExistingDisabled = createSelector(
  getMonitoringAgents,
  isGroupOwner,
  isMonitoringAdmin,
  (monitoringAgents, groupOwner, monitoringAdmin) => {
    const monitoringAgentEntries = monitoringAgents.entries || [];
    const hasPrimaryMonitoringAgent = monitoringAgentEntries.some((agent) => agent.isPrimary);
    return !(groupOwner || (monitoringAdmin && hasPrimaryMonitoringAgent));
  }
);

// explicit any to address this issue: https://github.com/microsoft/TypeScript/issues/43817
export const getLogCollectionJobs: any = createSelector(getActiveDeployment, (deployment) =>
  getOr([], 'logCollection.jobs', deployment)
);

export const getTotalJobUsedSize = createSelector(getLogCollectionJobs, (jobs) =>
  (jobs as any)
    .filter(({ status }) => status !== 'EXPIRED')
    .reduce((sum, { sizeTotalBytes }) => sum + sizeTotalBytes, 0)
);

export const getLogCollectionLogEstimates = (state, { resourceName, resourceType }) =>
  getActiveDeployment(state)?.logCollection?.logEstimates?.[resourceType]?.[resourceName];

// explicit any to address this issue: https://github.com/microsoft/TypeScript/issues/43817
export const getProcessConnectionInfo: any = (state, { processName }) =>
  get(['connectionInfo', 'process', processName], getActiveDeployment(state));

// explicit any to address this issue: https://github.com/microsoft/TypeScript/issues/43817
export const getReplicaSetConnectionInfo: any = (state, { rsId }) =>
  get(['connectionInfo', 'replicaSet', rsId], getActiveDeployment(state));

// explicit any to address this issue: https://github.com/microsoft/TypeScript/issues/43817
export const getClusterConnectionInfo: any = (state, { clusterName }) =>
  get(['connectionInfo', 'cluster', clusterName], getActiveDeployment(state));

/** Non-LTS versions (in 4.9+) are only allowed in groups with development versions (enabled for all Atlas groups) */
export const areQuarterlyVersionsAllowed = (state) =>
  settings.hasProjectFeature(state, 'AUTOMATION_MONGO_DEVELOPMENT_VERSIONS');

export const getSANAlertDocsUrl = createSelector(
  [app.isActiveOrgOnPrem, settings.getOnPremVersion],
  (isOnPrem, onPremVersion) => {
    if (isOnPrem) {
      // I'm not sure if onPremVersion can actually be null/undefined, but better to err on the side of caution.
      if (onPremVersion != null && onPremVersion.startsWith('4.2.')) {
        return 'https://dochub.mongodb.org/core/om4_2-ensure-tls-certificates-contain-a-subject-alternative-name';
      }
      return 'https://dochub.mongodb.org/core/om4_4-ensure-tls-certificates-contain-a-subject-alternative-name';
    }
    return 'https://dochub.mongodb.org/core/cm-ensure-tls-certificates-contain-a-subject-alternative-name';
  }
);

// Action Creators
const setCards = ({ groupId, cards }) => ({
  type: SET_CARDS,
  payload: cards,
  meta: {
    groupId,
  },
});

const setListViewItems = ({ groupId, items }) => ({
  type: SET_LIST_VIEW_ITEMS,
  payload: items,
  meta: { groupId },
});

const setLogCollectionJobs = ({ groupId, jobs }) => ({
  type: SET_LOG_COLLECTION_JOBS,
  payload: jobs,
  meta: { groupId },
});

const setLogEstimates = ({ groupId, logEstimates, resourceName, resourceType }) => ({
  type: SET_LOG_ESTIMATES,
  payload: logEstimates,
  meta: { groupId, resourceName, resourceType },
});

const setConnectionInfo = ({ automationId, groupId, type, connectionInfo }) => ({
  type: SET_CONNECTION_INFO,
  payload: connectionInfo,
  meta: { automationId, groupId, type },
});

const setUserPinnedCards = ({ groupId, pinnedCards }) => ({
  type: SET_PINNED_CARDS,
  payload: pinnedCards,
  meta: { groupId },
});

export const loadCardsForActiveGroup = () => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  return api.deployment.automation.getCards(groupId).then((cards) => {
    return dispatch(setCards({ groupId, cards }));
  });
};

export const loadListViewItemsForActiveGroup = () => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  return api.deployment.listView.getItems(groupId).then((items) => {
    items = items.sort((a, b) => {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      const clusterNameCompare = alphanumericCollator.compare(a.clusterName, b.clusterName);
      if (clusterNameCompare !== 0) {
        return clusterNameCompare;
      }

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      const replicaSetIdCompare = alphanumericCollator.compare(a.replicaSetId, b.replicaSetId);
      if (replicaSetIdCompare !== 0) {
        return replicaSetIdCompare;
      }

      const hostnameCompare = alphanumericCollator.compare(a.hostname, b.hostname);
      if (hostnameCompare !== 0) {
        return hostnameCompare;
      }

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      return alphanumericCollator.compare(a.port, b.port);
    });
    return dispatch(setListViewItems({ groupId, items }));
  });
};

export const removeMonitoredProcessesForActiveGroup = (processes) => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  const currentItems = getListViewItems(getState());

  const updatedItems = currentItems ? currentItems.filter((p) => !_.contains(processes, p)) : [];

  const hostIds = processes.map((p) => p.hostId);

  return api.deployment.monitoredProcesses.removeMonitoredProcesses(groupId, hostIds).then(() => {
    return dispatch(setListViewItems({ groupId, items: updatedItems }));
  });
};

const setAllAgents = ({ groupId, automation, backup, monitoring, planType }) => ({
  type: SET_ALL_AGENTS,
  payload: {
    automation,
    backup,
    monitoring,
  },
  meta: {
    groupId,
    planType,
  },
});

const setAutomationAgents = ({ groupId, automation, planType }) => ({
  type: SET_AUTOMATION_AGENTS,
  payload: {
    automation,
  },
  meta: {
    groupId,
    planType,
  },
});

const setMonitoringAgents = ({ groupId, monitoring, planType }) => ({
  type: SET_MONITORING_AGENTS,
  payload: {
    monitoring,
  },
  meta: {
    groupId,
    planType,
  },
});

export const loadAllAgentsForActiveGroup = () => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  dispatch({ type: SET_AGENTS_LOADING, meta: { groupId, agentType: 'all' } });

  return Promise.all([
    api.deployment.agents.getAutomationAgents(groupId),
    api.deployment.agents.getBackupAgents(groupId),
    api.deployment.agents.getMonitoringAgents(groupId),
  ]).then(([automation, backup, monitoring]) => {
    return dispatch(
      setAllAgents({
        groupId,
        automation,
        backup,
        monitoring,
        planType: app.getActiveOrgPlanType(getState()),
      })
    );
  });
};

export const loadAllAgentsForActiveGroupIfNeeded = fetchIfNeeded(
  loadAllAgentsForActiveGroup,
  wereAllAgentFetchesInitiated,
  (allInitiated) => !allInitiated
);

export const loadAutomationAgentsForActiveGroup = () => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  dispatch({ type: SET_AGENTS_LOADING, meta: { groupId, agentType: 'automation' } });

  return api.deployment.agents.getAutomationAgents(groupId).then((automation) => {
    return dispatch(
      setAutomationAgents({
        groupId,
        automation,
        planType: app.getActiveOrgPlanType(getState()),
      })
    );
  });
};

export const loadAutomationAgentsForActiveGroupIfNeeded = fetchIfNeeded(
  loadAutomationAgentsForActiveGroup,
  wasAutomationAgentFetchInitiated,
  (initiated) => !initiated
);

export const loadMonitoringAgentsForActiveGroup = () => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  dispatch({ type: SET_AGENTS_LOADING, meta: { groupId, agentType: 'monitoring' } });

  return api.deployment.agents.getMonitoringAgents(groupId).then((monitoring) => {
    return dispatch(
      setMonitoringAgents({
        groupId,
        monitoring,
        planType: app.getActiveOrgPlanType(getState()),
      })
    );
  });
};

const setDeploymentStatus = ({ groupId, deploymentStatus }) => ({
  type: SET_DEPLOYMENT_STATUS,
  payload: deploymentStatus,
  meta: {
    groupId,
  },
});

export const loadDeploymentStatusForActiveGroup = () => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  return api.deployment.automation.getDeploymentStatus(groupId).then((deploymentStatus) => {
    return dispatch(setDeploymentStatus({ groupId, deploymentStatus }));
  });
};

export const loadDeploymentDiffForGroup = () => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  return api.deployment.automation.getDeploymentDiff(groupId).then((deploymentDiff) => {
    return dispatch({
      type: SET_DEPLOYMENT_DIFF,
      payload: deploymentDiff.diffs || [],
      meta: {
        groupId,
      },
    });
  });
};

export const discardChangesForActiveGroup = () => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  dispatch({
    type: DISCARD_CHANGES,
  });

  return api.deployment.automation.discard(groupId).then(() => {
    return dispatch(loadDeploymentStatusForActiveGroup());
  });
};

const setMongoDBVersions = ({ groupId, versions }) => ({
  type: SET_MONGODB_VERSIONS,
  payload: versions,
  meta: {
    groupId,
  },
});

export const loadMongoDBVersionsForActiveGroup = () => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  return api.deployment.mongoDbVersion.getAvailableVersions(groupId).then((versions) => {
    return dispatch(setMongoDBVersions({ groupId, versions }));
  });
};

const setAgentAPIKeys = ({ groupId, keys }) => ({
  type: SET_AGENT_API_KEYS,
  payload: keys,
  meta: {
    groupId,
  },
});

export const loadAgentApiKeysForActiveGroup = () => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());
  const isViewerGroupOwner = isGroupOwner(getState());

  if (isViewerGroupOwner) {
    return api.deployment.agents.getAgentAPIKeys(groupId).then((keys) => {
      return dispatch(setAgentAPIKeys({ groupId, keys }));
    });
  }

  return [];
};

export const setAllowAutomationEditsWhilePublishing = (shouldAllow, groupIdArg) => (dispatch, getState) => {
  const groupId = groupIdArg || app.getActiveGroupId(getState());

  return dispatch({
    type: ALLOW_AUTOMATION_EDITS_WHILE_PUBLISHING,
    payload: shouldAllow,
    meta: {
      groupId,
    },
  });
};

export const setShowConfirmDeploymentChanges = (shouldShow) => (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  return dispatch({
    type: SHOW_CONFIRM_DEPLOYMENT_CHANGES_MODAL,
    payload: shouldShow,
    meta: {
      groupId,
    },
  });
};

export const loadJobsForActiveGroup = () => async (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  const jobs = await api.deployment.logCollection.getJobsForGroup({ groupId });

  dispatch(setLogCollectionJobs({ groupId, jobs }));
};

export const loadLogEstimatesForActiveGroup =
  ({ resourceName, resourceType }) =>
  async (dispatch, getState) => {
    const groupId = app.getActiveGroupId(getState());

    const logEstimates = await api.deployment.logCollection.getEstimateLogsSize(groupId, resourceName, resourceType);

    dispatch(setLogEstimates({ groupId, logEstimates, resourceName, resourceType }));
  };

export const loadProcessConnectionInfo = (processName) => async (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  const connectionInfo = await api.deployment.process.getConnectionInfo(groupId, processName);

  dispatch(
    setConnectionInfo({
      groupId,
      automationId: processName,
      type: 'process',
      connectionInfo,
    })
  );
};

export const loadReplicaSetConnectionInfo = (rsId) => async (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  const connectionInfo = await api.deployment.replicaSet.getConnectionInfo(groupId, rsId);

  dispatch(setConnectionInfo({ groupId, automationId: rsId, type: 'replicaSet', connectionInfo }));
};

export const loadClusterConnectionInfo = (clusterName) => async (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  const connectionInfo = await api.deployment.cluster.getConnectionInfo(groupId, clusterName);

  dispatch(
    setConnectionInfo({
      groupId,
      automationId: clusterName,
      type: 'cluster',
      connectionInfo,
    })
  );
};

export const loadUserPinnedCards = () => async (dispatch, getState) => {
  const groupId = app.getActiveGroupId(getState());

  const pinnedCards = await api.deployment.userPinnedCards.getPins(groupId);

  dispatch(setUserPinnedCards({ groupId, pinnedCards }));
};

export const hasUnmanagedAgentsSelector = createSelector(
  [getBackupAgents, getMonitoringAgents],
  (backupAgents, monitoringAgents) => monitoringAgents.isAnyAgentNotManaged || backupAgents.isAnyAgentNotManaged
);
