import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSelector,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';
import { WritableDraft } from 'immer';

import { ProcessArgument } from '@packages/types/deployment/editor';

import { getProcessArguments } from 'js/common/services/api/deployment/automationApi';

export interface ProcessArgumentsState {
  argList: Array<ProcessArgument>;
  error: SerializedError | null;
  status: 'uninitialized' | 'loading' | 'succeeded' | 'failed';
}

interface PartialRootState {
  processArguments: ProcessArgumentsState;
}

const initialState: ProcessArgumentsState = {
  argList: [],
  error: null,
  status: 'uninitialized',
};

export const fetchProcessArguments = createAsyncThunk<Array<ProcessArgument>, void, { state: PartialRootState }>(
  'processArguments/fetchProcessArguments',
  async () => {
    const response = await getProcessArguments();
    return response;
  },
  {
    // This is an expensive operation, we should only fetch if we don't have args
    // and there isn't an active or previously successful request.
    condition: (_args, { getState }) => {
      const { processArguments } = getState();
      const { status, argList } = processArguments;
      return argList.length === 0 && (status === 'uninitialized' || status === 'failed');
    },
  }
);

const processArgumentsSlice = createSlice({
  name: 'processArguments',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<WritableDraft<ProcessArgumentsState>>) => {
    builder
      .addCase(fetchProcessArguments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProcessArguments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.argList = action.payload;
        state.error = null;
      })
      .addCase(fetchProcessArguments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

const selectProcessArgsMap = createSelector(
  (state: PartialRootState) => state.processArguments.argList,
  (argsList) => Object.fromEntries(argsList.map((procArg) => [procArg.name, procArg]))
);

export const selectProcessArgName: (state: PartialRootState, argName: string) => ProcessArgument = createSelector(
  [selectProcessArgsMap, (_state, argName: string) => argName],
  (argsMap, argName) => argsMap[argName]
);

export default processArgumentsSlice.reducer;
