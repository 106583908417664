import { Action, ActionFromReducer, combineReducers } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import type createStore from './createStore';
import allClusters from './modules/allClusters';
import apiUser from './modules/apiUser';
import app from './modules/app';
import backupMetrics from './modules/backupMetrics';
import controlledFeatures from './modules/controlledFeatures';
import dataAPI from './modules/dataAPI/reducers';
import deployment from './modules/deployment';
import group from './modules/group';
import hostPartition from './modules/hostPartition';
import invoice from './modules/invoice';
import nds from './modules/nds/index';
import organization from './modules/organization';
import processArguments from './modules/processArgumentsSlice';
import purchase from './modules/purchase';
import settings from './modules/settings';
import team from './modules/team';
import triggers from './modules/triggers';
import uiPreferences from './modules/uiPreferences';
import user from './modules/user';
import viewer from './modules/viewer';

const rootReducer = combineReducers({
  allClusters,
  app,
  nds,
  dataAPI,
  deployment,
  hostPartition,
  group,
  invoice,
  organization,
  settings,
  team,
  uiPreferences,
  backupMetrics,
  user,
  apiUser,
  viewer,
  triggers,
  controlledFeatures,
  purchase,
  processArguments,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export type RootAction = ActionFromReducer<typeof rootReducer>;
export type RootThunk<ReturnType, A extends Action = RootAction> = ThunkAction<ReturnType, RootState, unknown, A>;
export type RootStore = ReturnType<typeof createStore<RootState, RootAction>>;
