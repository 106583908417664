import _ from 'underscore';

import { DeploymentStatus } from '@packages/types/deployment/deployment';
import { DeploymentDiff } from '@packages/types/deployment/deploymentDiff';
import { Process, ProcessArgument } from '@packages/types/deployment/editor';
import { Card } from '@packages/types/deployment/processes';

import fetchWrapper, { formParams } from '../fetchWrapper';

export function getCards(groupId: string): Promise<Array<Card>> {
  return fetchWrapper(`/automation/cards/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getProcesses(groupId: string): Promise<Array<Process>> {
  return fetchWrapper(`/automation/processes/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

// TODO(JeT): Define the DeploymentStatus type if we ever add types to our redux modules
export function getDeploymentStatus(groupId: string): Promise<DeploymentStatus> {
  return fetchWrapper(`/automation/deploymentStatus/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function discard(groupId: string): Promise<Response> {
  return fetchWrapper(`/automation/conf/discard/${groupId}`, {
    method: 'DELETE',
  });
}

export function publish(groupId: string): Promise<Response> {
  return fetchWrapper(`/automation/conf/publish/${groupId}`, {
    method: 'PUT',
  });
}

export function getDeploymentDiff(groupId: string): Promise<DeploymentDiff> {
  return fetchWrapper(`/automation/conf/diff/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAgentFileNames(groupId: string): Promise<{ [platform: string]: string }> {
  return fetchWrapper(`/automation/agent/namer/${groupId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

interface UnmanageParams {
  clearDeploymentItems: boolean;
  clearAgentSettings: boolean;
  clearAuthAndSSL: boolean;
  publish: boolean;
}
export function unmanage(options: Partial<UnmanageParams> & { groupId: string }): Promise<Response> {
  const { groupId } = options;

  const params: UnmanageParams = {
    clearDeploymentItems: true,
    clearAgentSettings: true,
    clearAuthAndSSL: true,
    publish: false,
    ..._.pick(options, ['clearDeploymentItems', 'clearAgentSettings', 'clearAuthAndSSL', 'publish']),
  };

  return fetchWrapper(`/automation/conf/unmanage/${groupId}?${formParams(params)}`, {
    method: 'DELETE',
  });
}

export function getProcessArguments(): Promise<Array<ProcessArgument>> {
  return fetchWrapper('/automation/processArguments', {
    method: 'GET',
  }).then((resp) => resp.json());
}
